import React, { useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { handleGeInvoice, handleGetOrder } from "../../store/thunk/checkout.js";
import styled from "styled-components";
const NoPrintButton = styled(Button)`
  @media print {
    display: none;
  }
`;

const ERecipt = ({ x, qrCodeUrl }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, fetching, error } = useSelector((state) => state.checkout.list);

  useEffect(() => {
    if (id) {
      console.log(`Fetching invoice for ID: ${id}`);
      dispatch(handleGeInvoice(id));
    } else {
      console.log("No ID found, navigating to notfound page.");
      navigate("/notfound");
    }
  }, [id, navigate, dispatch]);

  useEffect(() => {
    console.log("Data updated:", data);
  }, [data]);

  if (fetching) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  if (!data) return <div>No data found</div>;
  const baseUrl = "https://dtiketin.com/static/media/";
  const mediaLogo = data?.booking?.event?.event_logo;

  return (
    <Container>
      <Box
        boxShadow={3}
        p={3}
        border={1}
        borderColor="#eee"
        mt={3}
        style={{ backgroundColor: "white" }}
      >
        <Box mb={2}>
          <Grid container spacing={22}>
            <Grid item md={6} textAlign="left">
              <img
                src={`${baseUrl}${mediaLogo}`}
                alt="images"
                style={{ maxWidth: "150px" }}
              />
            </Grid>
            <Grid item md={6} textAlign="right">
              <Typography variant="caption text" style={{ fontSize: "10px" }}>
                Invoice #{data.order_id}
                <br />
                Created: {data.created_date}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mb={2}>
          <Grid container spacing={20}>
            <Grid item md={6} textAlign="left">
              <Typography variant="h5">Booking Details</Typography>
              <Typography variant="body1">
                <strong>Booking ID:</strong>{" "}
                {data?.booking?.event?.event_code_name}-
                {data.booking.booking_id}-{data.created_date}
              </Typography>
              <Typography variant="body1">
                <strong>Full Name:</strong> {data.booking.booking_full_name}
              </Typography>
              {/* <Typography variant="body1"><strong>Phone Number:</strong> {data.booking.booking_phone_number}</Typography> */}
              <Typography variant="body1">
                <strong>Email:</strong> {data.booking.booking_email}
              </Typography>
            </Grid>
            <Grid item md={6} textAlign="right">
              <Typography variant="h5">Event Organizer</Typography>
              <Typography variant="body1">
                DTIKETIN.
                <br />
                {data?.booking?.event?.event_email}
                <br />
                085172416033
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Jersey Size</TableCell>
                {data?.isJerseyKids && <TableCell>Jersey Size Kids</TableCell>}
                <TableCell>Category</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.booking.booking_tickets.map((ticket) =>
                ticket.participants.map((participant) => (
                  <TableRow key={participant.full_name}>
                    <TableCell>{participant?.full_name}</TableCell>
                    {/* <TableCell>{participant?.size_jersey.substring(37)}</TableCell> */}
                    <TableCell>{participant?.size_jersey}</TableCell>

                    {data?.isJerseyKids && (
                      <TableCell>
                        {participant?.size_jersey_kids?.substring(39)}
                      </TableCell>
                    )}
                    {/* <TableCell>
                                            {ticket.ticket_id === 1 ? '3K FAMILY RUN' :
                                                (ticket.ticket_id === 2 ? '5K FUN RUN' :
                                                    (ticket.ticket_id === 3 ? '10K RACE' : ''))}
                                        </TableCell> */}
                    <TableCell>{ticket?.ticket.ticket_name}</TableCell>
                  </TableRow>
                )),
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={3} textAlign="right">
          <Typography variant="h5" borderTop={2} borderColor="#eee" pt={2}>
            Total Payment: Rp.{" "}
            {JSON.parse(data.payment).sumTicketCost.toLocaleString("id-ID")}
          </Typography>
        </Box>

        <Box textAlign="center" mt={3}>
          {/* <Typography variant="body1">Unduh file pdf: <a href={`https://dtiketin.com/api/order/generate/${data.order_id}`}>https://dtiketin.com/api/order/generate/{data.order_id}</a></Typography> */}
          <img src={data.qrCode} alt="QR Code" style={{ maxWidth: "150px" }} />
        </Box>
        <Box mt={3}>
          <NoPrintButton
            variant="contained"
            color="primary"
            onClick={() => window.print()}
            className="no-print"
          >
            Download E-Ticket
          </NoPrintButton>
        </Box>
      </Box>
    </Container>
  );
};

export default ERecipt;
