import React from "react";
import { Field, ErrorMessage } from "formik";
import { TextField, Typography } from "@mui/material";

const FormikTextField = ({ name, label, ...props }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <>
          <TextField
            {...field}
            {...props}
            fullWidth
            label={label}
            variant="outlined"
            helperText={<ErrorMessage name={name} />}
            error={!!form.errors[name] && form.touched[name]}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px",
              },
            }}
          />
          {name === "phone" ? (
            <Typography variant="caption" color="textSecondary">
              WhatsApp Number Active (ex: 08xxxxxxxx)
            </Typography>
          ) : null}
        </>
      )}
    </Field>
  );
};

export default FormikTextField;
