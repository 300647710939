import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleCheckout } from "../../store/thunk/checkout";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Collapse,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const InvoiceForm = ({ data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [agreed, setAgreed] = useState(false);
  const checkoutStatus = useSelector(
    (state) => state.checkout.addChekout.status,
  );

  // Now you can check the status:
  const isLoading = checkoutStatus === "loading";
  const isSuccess = checkoutStatus === "success";
  const isError = checkoutStatus === "error";

  const handleAgreeChange = (event) => {
    setAgreed(event.target.checked);
  };

  const [openSections, setOpenSections] = useState({});

  useEffect(() => {
    const initialOpenSections = data.dataTickets.reduce((acc, ticket) => {
      acc[ticket.ticket_tag] = true;
      return acc;
    }, {});
    setOpenSections(initialOpenSections);
  }, [data.dataTickets]);

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleSubmit = () => {
    try {
      dispatch(handleCheckout({ ...data }));
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };
  const renderBoldLabelWithData = (label, data) => (
    <Typography variant="body1">
      <Typography component="span" variant="body1" fontWeight="bold">
        {label}
      </Typography>
      {` ${data}`}
    </Typography>
  );

  const renderParticipantDetails = (participants, title, sectionKey) => (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={() => toggleSection(sectionKey)}>
          {openSections[sectionKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={openSections[sectionKey]}>
        {participants.length > 0 ? (
          participants.map((participant, index) => {
            return (
              <Box
                key={index}
                mb={2}
                p={2}
                border="1px solid #ccc"
                borderRadius="4px"
              >
                {renderBoldLabelWithData("Full Name:", participant.fullName)}
                {renderBoldLabelWithData("Email:", participant.email)}
                {renderBoldLabelWithData("Phone:", participant.phone)}
                {renderBoldLabelWithData(
                  "Birth Date:",
                  new Date(participant.birth).toLocaleDateString(),
                )}
                {renderBoldLabelWithData("Gender:", participant.gender)}
                {renderBoldLabelWithData("NIK:", participant.nik)}
                {renderBoldLabelWithData(
                  "Nationality:",
                  participant.nationality,
                )}
                {renderBoldLabelWithData("Full Address:", participant.address)}
                {renderBoldLabelWithData("Blood Type:", participant.bloodType)}
                {renderBoldLabelWithData(
                  "Emergency Contact Name:",
                  participant.emergencyContactName,
                )}
                {renderBoldLabelWithData(
                  "Emergency Contact Phone:",
                  participant.emergencyContactPhone,
                )}
                {renderBoldLabelWithData(
                  "Jersey Size:",
                  participant.jerseySize,
                )}
                {/* {participant.jerseySize !== '' ? renderBoldLabelWithData('Jersey Size:', participant.jerseySize.split(' - ')[1]) : null} */}
                {participant.jerseySizeKids !== ""
                  ? renderBoldLabelWithData(
                      "Jersey Size Kids:",
                      participant.jerseySizeKids.split("_")[1],
                    )
                  : null}
              </Box>
            );
          })
        ) : (
          <Typography variant="body2">No participants</Typography>
        )}
      </Collapse>
    </>
  );

  return (
    <Card sx={{ p: 3, mb: 3 }}>
      <CardContent>
        <Box display="flex" justifyContent="center">
          <Typography variant="h4" gutterBottom>
            Invoice Form
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Billing Information</Typography>
            <TextField
              label="Full Name"
              value={data.booking.fullName}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Email"
              value={data.booking.email}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Phone"
              value={data.booking.phone}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Payment Information
            </Typography>
            {Object.entries(data.participants).map(([key, participants]) => {
              const ticket = data.dataTickets.find(
                (ticket) => ticket.ticket_tag === key,
              );
              return (
                participants.length > 0 && (
                  <Grid item xs={12} key={key}>
                    {renderParticipantDetails(
                      participants,
                      `Participants for ${ticket ? ticket.ticket_name : key}`,
                      key,
                    )}
                  </Grid>
                )
              );
            })}
            <Typography variant="body1">
              <Typography component="span" variant="body1" fontWeight="bold">
                Total Cost :{" "}
              </Typography>
              Rp.{data.payment.sumTicketCost.toLocaleString("id-ID")}
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="h6">Redeem Code</Typography>
            <TextField
              label="Redeem Code"
              value={data.redeemCode}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: true }}
            />
          </Grid> */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreed}
                  onChange={handleAgreeChange}
                  name="agree"
                />
              }
              label={t("declare")}
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={!agreed || isLoading || isSuccess || isError}
            // disabled={true}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InvoiceForm;
