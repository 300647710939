import React, { useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, TextField, Button, Typography, Grid, Card, CardContent, Table, TableBody, TableRow, TableCell, FormLabel } from '@mui/material';

const ResultData = ({
  scannedData,
  handleClaim,
}) => {
  const [claimType, setClaimType] = useState({});
  const [claimedByName, setClaimedByName] = useState({});

  const handleClaimTypeChange = (index, value) => {
    setClaimType(prev => ({ ...prev, [index]: value }));

    if (value === 'self') {
      setClaimedByName(prev => ({ ...prev, [index]: scannedData[index]?.full_name }));
    } else {
      setClaimedByName(prev => ({ ...prev, [index]: '' }));
    }
  };

  const handleClaimedByNameChange = (index, value) => {
    setClaimedByName(prev => ({ ...prev, [index]: value }));
  };

  return (
    <Grid container spacing={2} sx={{ padding: '10px', margin: 'auto', justifyContent: scannedData.length <= 2 ? 'center' : 'flex-start' }}>
      {scannedData.map((data, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} sx={{ marginTop: '20px', padding: '10px' }}>
          <Card variant="outlined" sx={{ borderRadius: '20px' }}>
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                {data.event_name}
              </Typography>
              <Table>
                <TableBody>
                  {Object.entries({
                    'Order ID': data?.order_id,
                    'Full Name': data?.full_name,
                    'Gender': data?.gender,
                    'Ticket': data?.tickets || data?.ticket_name,
                    'Size Jersey': data?.size_jersey,
                    'BIB Number': data?.bib_number,
                  }).map(([label, value], idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <Typography variant="body2">{label}:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2"><strong>{value}</strong></Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {data.claimed_status === null || data.claimed_status === "" ? (
                <FormControl component="fieldset" sx={{ marginTop: '10px' }}>
                  <FormLabel component="legend">Claim By</FormLabel>
                  <RadioGroup
                    row
                    value={claimType[index] || 'self'}
                    onChange={(e) => handleClaimTypeChange(index, e.target.value)}
                  >
                    <FormControlLabel value="self" control={<Radio />} label="Self" />
                    <FormControlLabel value="other" control={<Radio />} label="Claim by Someone Else" />
                  </RadioGroup>
                </FormControl>
              ) : null
              }
              {claimType[index] === 'other' && (
                <TextField
                  fullWidth
                  label="Claimed By Name"
                  value={claimedByName[index] || ''}
                  onChange={(e) => handleClaimedByNameChange(index, e.target.value)}
                  sx={{ marginTop: '10px' }}
                />
              )}

              {/* Button Claim */}
              {data.claimed_status === null || data.claimed_status === "" ? (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleClaim({
                    ...data,
                    claimType: claimType[index],
                    claimedByName: claimType[index] === 'self' ? data.full_name : claimedByName[index],
                  })}
                  sx={{
                    marginTop: '10px',
                    borderRadius: '20px',
                    color: 'white',
                    backgroundColor: 'red',
                    '&:hover': {
                      backgroundColor: '#d32f2f',
                    },
                  }}
                >
                  Klaim
                </Button>
              ) : (
                <Typography variant="body1" color="green" sx={{ marginTop: '10px' }}>
                  {!data?.claimed_by === 'other' 
                    ? 'Sudah Diambil' 
                    : (
                        <>
                          Sudah Diambil <strong>'{data?.claimed_by_name}'</strong>
                        </>
                      )
                  }
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ResultData;
