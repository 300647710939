import { eventActions } from "../slices/events"; // Adjust the import path as necessary
import { getEvents, getEventsDetail, getTickets } from "../../services/event"; // Adjust the import path as necessary

export const handleGetEvent = (param) => async (dispatch) => {
  try {
    dispatch(eventActions.getEventRequest());
    const response = await getEvents(param);

    // Check if response has 'data' and sort it by 'created_date'
    if (response?.data?.result) {
      const sortedEvents = response.data.result.sort(
        (a, b) => new Date(b.created_date) - new Date(a.created_date),
      );
      // Dispatch the sorted events
      dispatch(
        eventActions.getEventSuccess({
          ...response.data,
          result: sortedEvents,
        }),
      );
    } else {
      throw new Error("No events found");
    }
  } catch (error) {
    dispatch(eventActions.getEventFailed(error.message));
  }
};

export const handleGetEventDetail = (id) => async (dispatch) => {
  try {
    dispatch(eventActions.getEventDetailRequest());
    const { data } = await getEventsDetail(id);
    dispatch(eventActions.getEventDetailSuccess(data));
  } catch (error) {
    dispatch(eventActions.getEventDetailFailed(error.message));
  }
};

export const handleGetTicket = (id) => async (dispatch) => {
  try {
    dispatch(eventActions.getTicketRequest());
    const { data } = await getTickets(id);
    dispatch(eventActions.getTicketSuccess(data));
  } catch (error) {
    dispatch(eventActions.getTicketFailed(error.message));
  }
};
