export const renderThemeClass = (theme) => {
  return theme &&
    (theme === "secondary" || theme === "third" || theme === "fourth")
    ? `-theme--${theme}`
    : "";
};

export const calculateTotalPrice = (arr = []) => {
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  return arr.reduce(reducer, 0);
};

