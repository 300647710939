import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import logger from "redux-logger";

const store = configureStore({
  reducer: rootReducer,
  //middleware: (getDefaultMiddleware) => {
  //  return process.env.NODE_ENV !== 'production'
  //    ? getDefaultMiddleware().concat(logger)
  //    : getDefaultMiddleware();
  //},
});

export default store;
