import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@fontsource/poppins'; // Defaults to weight 400.
import '@fontsource/poppins/400.css'; // Regular
import '@fontsource/poppins/700.css'; // Bold
import './i18n'; // Import konfigurasi i18n

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
