import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import id from './id.json';

i18n
  .use(LanguageDetector) // Untuk mendeteksi bahasa browser
  .use(initReactI18next) // Integrasi dengan React
  .init({
    resources: {
      en: { translation: en },
      id: { translation: id },
    },
    fallbackLng: 'en', // Bahasa default
    debug: true,       // Aktifkan log untuk debugging
    interpolation: {
      escapeValue: false, // React sudah aman dari XSS
    },
  });

export default i18n;
