import React, { useEffect, useState } from "react";
import CardList from "./Home/CardList";
import ImageCarousel from "./Home/Carousel";
import ThemeVariation from "../theme/theme-variation";
import Layout from "./layout";
import { Container } from "@mui/material";
import { handleNavigationHome } from "../utils/navigationUtils";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    handleNavigationHome();

    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get("order_id");
    const statusCode = urlParams.get("status_code");
    const transactionStatus = urlParams.get("transaction_status");

    if (orderId) {
      navigate(
        `/payment?order_id=${orderId}&status_code=${statusCode}&transaction_status=${transactionStatus}`,
      );
    }
  }, [navigate]);

  const THEME = ThemeVariation.Secondary;

  return (
    <Layout theme={THEME} showHeaderSpacing={false} color={ThemeVariation.Base}>
      {/* <SectionContainer>
          <HeroSliderCarousel theme={THEME} />
      </SectionContainer> */}
      <ImageCarousel />
      <Container>
        <h2>Our Events</h2>
        <CardList />
      </Container>
    </Layout>
  );
};

export default Home;
