import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Skeleton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { handleGetEvent } from "../../store/thunk/events";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import SkeletonCard from "./CardSkeleton";

const CardList = () => {
  const dispatch = useDispatch();

  const { data, fetching, error } = useSelector((state) => state.event.list);

  useEffect(() => {
    dispatch(handleGetEvent());
  }, [dispatch]);

  // if (fetching) return <SkeletonCard />;
  if (error) return <div>Error: {error}</div>;

  // Ensure data.result exists and is an array
  const eventData = data?.result ?? [];
  const url = "dtiketin.com";
  const handleNavigation = (item, e) => {
    if (process.env.NODE_ENV === "production") {
      e.preventDefault();
      window.location.href = `https://${item.subdomain}.${url}/event/${item.event_id}`;
    }
  };
  return (
    <Grid container spacing={2}>
      {fetching && (
        <Grid item xs={12} sm={6} md={4}>
          {" "}
          <SkeletonCard />{" "}
        </Grid>
      )}
      {eventData.map((item) => (
        <Grid item xs={12} sm={6} md={4} key={item.event_id}>
          <Link
            to={
              process.env.NODE_ENV !== "production"
                ? `/event/${item.event_id}`
                : "#"
            }
            onClick={(e) => handleNavigation(item, e)}
            style={{ textDecoration: "none" }}
          >
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={require(`../../assets/${item.event_banner}`)}
                alt={item.event_banner}
              />
              <CardContent>
                <Typography variant="body2">{item.event_venue}</Typography>
                <Typography component="div" variant="h6">
                  {item.event_name}
                </Typography>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <EventIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      {item.event_date}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <LocationOnIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="textSecondary">
                      {item.event_venue}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardList;
