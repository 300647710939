import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Stack,
  Breadcrumbs,
  Link,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  Tabs,
  Tab,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ThemeVariation from "../../theme/theme-variation.js";
import Layout from "../layout/index.jsx";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CountUp from "react-countup";
import { handleLogin } from "../../store/thunk/auth.js";
import {
  getCountClaimed,
  searchClaimed,
  postClaim,
} from "../../store/thunk/dashboard.js";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import "./style.css";
// import QrReader from 'react-qr-scanner';
import { QrReader } from "react-qr-reader";
import localStorageAvailable, {
  jwtDecode,
} from "../../utils/localStorageAvailable";
import { useFormik } from "formik";
import * as Yup from "yup";
import ResultData from "./ResultData.jsx";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storageAvailable = localStorageAvailable();

  const { loading } = useSelector((state) => state.auth.login);
  const { data } = useSelector((state) => state.dashboard.list);
  const { data: dataSearch } = useSelector((state) => state.dashboard.search);

  const [openLoginDialog, setOpenLoginDialog] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [name, setName] = useState("");
  const [qrCodeResult, setQrCodeResult] = useState(null);
  const [userLogin, setUserLogin] = useState({});
  const [isCameraOpen, setIsCameraOpen] = useState(true);
  // const [scannedData, setScannedData] = useState([]);
  const [valQr, setValQe] = useState("");

  const [scannedData, setScannedData] = useState([]); // Data scanned
  const [claimType, setClaimType] = useState([]); // Array to store claim type for each scannedData item
  const [claimedByName, setClaimedByName] = useState({}); // Object to store claimedByName for each scannedData

  const accessToken = storageAvailable
    ? localStorage.getItem("accessToken")
    : null;

  useEffect(() => {
    if (accessToken) {
      setOpenLoginDialog(false);
      const decoded = jwtDecode(accessToken);
      setUserLogin(decoded);
      setOpenLoginDialog(false);
      dispatch(getCountClaimed());
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    setOrderId("");
    setName("");
    setQrCodeResult(null);
    setScannedData([]);
    handleRetryScan();
  }, [tabValue]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (dataSearch?.result) {
      setScannedData(dataSearch?.result);
    }

    if (dataSearch?.result.length !== 0) {
      setQrCodeResult(null);
    }

    if (dataSearch?.result.length === 0) {
      setQrCodeResult("Data tidak ditemukan");
    }
  }, [dataSearch?.result]);

  const handleSearch = () => {
    const dt = {
      type: orderId ? "orderId" : "fullName",
      value: orderId ? orderId : name,
    };
    dispatch(searchClaimed(dt));
  };

  const handleQrScan = (data) => {
    if (data) {
      setValQe(data?.text);
      const dt = {
        type: "qr",
        value: data?.text,
      };
      dispatch(searchClaimed(dt));
      setIsCameraOpen(false);
      if (dataSearch?.result.length === 0) {
        setQrCodeResult(data?.text);
      }
    }
  };

  const handleRetryScan = () => {
    setQrCodeResult(null);
    setIsCameraOpen(true);
  };

  const handleClaimTypeChange = (index, value) => {
    const updatedClaimType = [...claimType];
    updatedClaimType[index] = value;
    setClaimType(updatedClaimType);
  };

  const handleClaimedByNameChange = (index, value) => {
    setClaimedByName((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleClaim = async (param, index) => {
    const data = {
      booking_tickets_id: param?.booking_tickets_id,
      orderId: param?.order_id,
      claimed_by: param?.claimType,
      claimed_by_name: param?.claimedByName,
    };

    try {
      const res = await dispatch(postClaim(data));

      if (res?.code === 0) {
        const dt = {
          type: tabValue === 2 ? "qr" : orderId ? "orderId" : "fullName",
          value: tabValue === 2 ? valQr : orderId ? orderId : name,
        };
        await dispatch(searchClaimed(dt));
        // Memanggil handleReload setelah klaim berhasil
        handleReload();
      }
    } catch (error) {
      console.error("Error during claim:", error);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleReload = () => {
    dispatch(getCountClaimed());
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/dashboard";
  };

  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(handleLogin(values));
    },
  });

  const THEME = ThemeVariation.Secondary;

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="#"
      onClick={() => navigate("/")}
    >
      HOME
    </Link>,
    <Link underline="hover" key="2" color="inherit">
      Dashboard
    </Link>,
  ];

  if (loading) return <p>Loading...</p>;

  return (
    <Layout theme={THEME} showHeaderSpacing={false} color={ThemeVariation.Base}>
      <Container>
        <Dialog open={openLoginDialog} onClose={() => setOpenLoginDialog(true)}>
          <DialogTitle>Login</DialogTitle>
          <DialogContent>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                autoFocus
                margin="dense"
                label="Username"
                fullWidth
                id="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
              <TextField
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <DialogActions>
                <Button type="submit" color="primary" variant="contained">
                  Login
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
        <Stack spacing={2} mb={3} mt={4}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        {accessToken && (
          <Card
            sx={{
              mt: 2,
              borderRadius: "20px",
              boxShadow: "0 8px 30px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent>
              <Grid item xs={12} md={12}>
                <Typography variant="h5" component="div" textAlign="left">
                  Welcome, {userLogin?.username}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} textAlign="left" sx={{ mt: 2 }}>
                <Button
                  onClick={handleLogout}
                  color="secondary"
                  variant="contained"
                  sx={{
                    borderRadius: "20px",
                    backgroundColor: "red",
                    "&:hover": {
                      backgroundColor: "#d32f2f",
                    },
                  }}
                  startIcon={<LogoutIcon />}
                >
                  Logout
                </Button>
              </Grid>
              <Grid item xs={12} md={4} textAlign="right">
                <Button
                  onClick={handleReload}
                  color="primary"
                  variant="contained"
                  startIcon={<RefreshIcon />}
                  sx={{ borderRadius: "20px" }}
                >
                  Refresh
                </Button>
              </Grid>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h4" component="div" textAlign="left">
                    Racepack
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      mt: 2,
                      borderRadius: "20px 20px 0 0",
                      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h1"
                        component="div"
                        textAlign="center"
                      >
                        <CountUp
                          start={0}
                          end={data?.countClaimed}
                          duration={2}
                        />
                      </Typography>
                    </CardContent>
                  </Card>
                  <Typography
                    variant="h4"
                    component="div"
                    textAlign="center"
                    sx={{
                      backgroundColor: "#2196f3",
                      color: "white",
                      padding: "8px",
                      borderRadius: "0 0 20px 20px",
                      mt: 0,
                      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    CLAIMED
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      mt: 2,
                      borderRadius: "20px 20px 0 0",
                      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h1"
                        component="div"
                        textAlign="center"
                      >
                        <CountUp
                          start={0}
                          end={data?.countUnclaimed}
                          duration={2}
                        />
                      </Typography>
                    </CardContent>
                  </Card>
                  <Typography
                    variant="h4"
                    component="div"
                    textAlign="center"
                    sx={{
                      backgroundColor: "yellow",
                      color: "black",
                      padding: "8px",
                      borderRadius: "0 0 20px 20px",
                      mt: 0,
                      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    UNCLAIMED
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
                  <Typography variant="h4" component="div" textAlign="left">
                    Filter By
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="filter tabs"
                      centered
                    >
                      <Tab label="Order ID" />
                      <Tab label="Name" />
                      <Tab label="Scan" />
                    </Tabs>
                  </Box>

                  {tabValue === 0 && (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ marginTop: "10px" }}
                    >
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          label="Enter Order ID"
                          value={orderId}
                          onChange={(e) => setOrderId(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "20px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          onClick={handleSearch}
                          color="primary"
                          variant="contained"
                          startIcon={<SearchIcon />}
                          sx={{ borderRadius: "20px" }}
                        >
                          Search
                        </Button>
                      </Grid>
                      <ResultData
                        scannedData={scannedData}
                        claimType={claimType}
                        claimedByName={claimedByName}
                        handleClaim={handleClaim}
                        handleClaimTypeChange={handleClaimTypeChange}
                        handleClaimedByNameChange={handleClaimedByNameChange}
                      />
                      {qrCodeResult && (
                        <>
                          <Typography sx={{ color: "red", marginTop: "20px" }}>
                            {qrCodeResult}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  )}

                  {tabValue === 1 && (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ marginTop: "10px" }}
                    >
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          label="Enter Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "20px",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          onClick={handleSearch}
                          color="primary"
                          variant="contained"
                          startIcon={<SearchIcon />}
                          sx={{ borderRadius: "20px" }}
                        >
                          Search
                        </Button>
                      </Grid>
                      <ResultData
                        scannedData={scannedData}
                        claimType={claimType}
                        claimedByName={claimedByName}
                        handleClaim={handleClaim}
                        handleClaimTypeChange={handleClaimTypeChange}
                        handleClaimedByNameChange={handleClaimedByNameChange}
                      />
                      {qrCodeResult && (
                        <>
                          <Typography sx={{ color: "red", marginTop: "20px" }}>
                            {qrCodeResult}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  )}

                  {tabValue === 2 && (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ marginTop: "10px" }}
                    >
                      <Grid item xs={12} textAlign="center">
                        <Typography variant="h6">Scan QR Code</Typography>
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "400px",
                            margin: "0 auto",
                            position: "relative",
                          }}
                        >
                          {isCameraOpen && (
                            <>
                              {/* <QrReader
                                    delay={300}
                                    onError={handleError}
                                    onScan={handleQrScan}
                                    style={{ width: '100%' }}
                                    // constraints={{ facingMode: 'environment' }}
                                  />  */}
                              <QrReader
                                onResult={(result, error) => {
                                  if (!!result) {
                                    handleQrScan(result);
                                  }
                                  if (!!error) {
                                    handleError(error);
                                  }
                                }}
                                style={{ width: "100%" }}
                                constraints={{ facingMode: "environment" }}
                              />
                              <div className="qr-overlay">
                                <div className="qr-corner top-left" />
                                <div className="qr-corner top-right" />
                                <div className="qr-corner bottom-left" />
                                <div className="qr-corner bottom-right" />
                                <div className="scan-line" />
                              </div>
                            </>
                          )}
                        </Box>
                        {!isCameraOpen && (
                          <Button
                            onClick={handleRetryScan}
                            color="primary"
                            variant="contained"
                            startIcon={<RefreshIcon />}
                            sx={{ marginTop: "10px", borderRadius: "20px" }}
                          >
                            Scan Ulang
                          </Button>
                        )}
                        {qrCodeResult && (
                          <>
                            <Typography
                              sx={{ color: "red", marginTop: "20px" }}
                            >
                              SCAN QR FAILED
                            </Typography>
                          </>
                        )}
                        {/* <ResultData scannedData={scannedData} handleClaim={handleClaim} /> */}
                        <ResultData
                          scannedData={scannedData}
                          claimType={claimType}
                          claimedByName={claimedByName}
                          handleClaim={handleClaim}
                          handleClaimTypeChange={handleClaimTypeChange}
                          handleClaimedByNameChange={handleClaimedByNameChange}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Container>
    </Layout>
  );
};

export default Dashboard;
