import React, { useState, useEffect } from "react";
import { commonActions } from "../../store/slices/common.js";
import { getValidateVoucher } from "../../services/voucher.js";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Popover,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FormParticipants from "./FormParticipants"; // Ensure this component exists and is imported correctly
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const EventTickets = ({
  flagPurchase,
  setFlagPurchase,
  ticket,
  form,
  name,
  dataTickets,
  index,
  setDiscount,
}) => {
  const {
    ticket_name,
    event,
    ticket_price,
    ticket_quantity,
    ticket_id,
    ticket_type,
    event_id,
  } = ticket;
  const [year, month, day] = event.event_date.split("-");
  const formattedDate = `${day}-${month}-${year}`;
  const [open, setOpen] = useState(false);
  const [participants, setParticipants] = useState({});
  const [bundlingCount, setBundlingCount] = useState(1);
  //const [voucherName, setVoucherName ] = useState('')
  const { t } = useTranslation();

  useEffect(() => {
    const initialTickets = dataTickets.reduce((acc, ticket) => {
      acc[ticket.name] = [];
      return acc;
    }, {});
    setParticipants(initialTickets);
  }, [dataTickets]);

  const handleClickOpen = (count = 1) => {
    setOpen(true);
    setBundlingCount(count);
    setFlagPurchase(name);
  };

  const handleClose = () => {
    setOpen(false);
    setParticipants(form);
  };
  const dispatch = useDispatch();

  const handleConfirm = async () => {
    const voucherArrName = form["FSPNR1"] && form["FSPNR1"][0]?.voucher_name;
    if (form["FSPNR1"] && voucherArrName) {
      try {
        const payload = {
          event_id: 5,
          voucher_name: voucherArrName,
        };

        const res = await getValidateVoucher(payload);
        const voucherDisc = res?.data?.data?.voucher_discount_price;
        setDiscount(voucherDisc);

        setOpen(false);
        setParticipants(form);
      } catch (error) {
        dispatch(
          commonActions.showToast({
            message: "Invalid Voucher Code",
            type: "error",
          }),
        );
      }
    } else {
      setOpen(false);
      setParticipants(form);
      setDiscount(0);
    }
  };

  const disableKeyPurchaseTicketButton = {
    FSPNR1: 1,
    FSPNR3: 3,
    FSPNR5: 5,
  };
  return (
    <Card sx={{ display: "flex", width: "100%", mb: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CardContent sx={{ flex: "1 0 auto", position: "relative" }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={8}>
              <Typography component="div" variant="h6">
                {ticket_name}
              </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <ConfirmationNumberIcon />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    fontWeight="bold"
                  >
                    Rp. {ticket_price.toLocaleString("id-ID")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <EventIcon />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {formattedDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <LocationOnIcon />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {event.event_venue}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  height: "100%",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleClickOpen(
                      disableKeyPurchaseTicketButton[
                        dataTickets[index].ticket_tag
                      ],
                    )
                  }
                  disabled={
                    (flagPurchase && flagPurchase !== name) ||
                    !(
                      Boolean(dataTickets[index]?.ticket_availability) &&
                      dataTickets[index]?.ticket_quantity !== 0
                    ) ||
                    dataTickets[index].ticket_quantity <
                      disableKeyPurchaseTicketButton[
                        dataTickets[index].ticket_tag
                      ]
                  }
                  sx={{ borderRadius: "20px", position: "sticky", bottom: 0 }}
                >
                  {t("purchaseTicket")}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          {!participants[name] && (
            <Typography component="div" variant="h6" sx={{ mt: 2 }}>
              Participants List
            </Typography>
          )}
          <List>
            {participants[name]?.map((participant, index) => {
              let isParticipantIncomplete =
                participant.fullName === "" ||
                participant.email === "" ||
                participant.birth === "" ||
                participant.gender === "" ||
                participant.nik === "" ||
                participant.phone === "" ||
                participant.nationality === "" ||
                participant.address === "" ||
                participant.bloodType === "" ||
                participant.emergencyContactName === "" ||
                participant.emergencyContactPhone === "" ||
                (ticket_type === "family"
                  ? participant.jerseySize === "" &&
                    participant.jerseySizeKids === ""
                  : participant.jerseySize === "");
              return (
                <ListItem key={index}>
                  {isParticipantIncomplete ? (
                    <Typography variant="body1" style={{ color: "red" }}>
                      Please complete participant data
                    </Typography>
                  ) : (
                    <ListItemText
                      primary={`${index + 1}. ${participant.fullName}`}
                      secondary={participant.email}
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Participants</DialogTitle>
        <DialogContent>
          <FormParticipants
            setFlagPurchase={setFlagPurchase}
            bundlingCount={bundlingCount}
            name={name}
            ticketQuantity={ticket_quantity}
            ticketId={ticket_id}
            ticketType={ticket_type}
            eventId={event_id}
            values={form}
            indexTicket={index}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EventTickets;
