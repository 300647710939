import ConnectionInstance from "./connection-instance";

export const addCheckout = (param) =>
  ConnectionInstance.post("api/order/add", param);
export const getChekout = (params) =>
  ConnectionInstance.get("checkout", { params });
export const getOrderId = (id, status) =>
  ConnectionInstance.get("/api/order/find/" + id + "/" + status);
export const getInvoice = (id) =>
  ConnectionInstance.get("/api/order/invoice/" + id);
export const getCountJawararun = (id) =>
  ConnectionInstance.get("api/payments/jawararun/monitoring");
export const getCountHeroes = (id) =>
  ConnectionInstance.get("api/payments/heroestrailrun/monitoring");
export const getCountAesifunrun = (id) =>
  ConnectionInstance.get("api/payments/aesirun/monitoring");
export const getCountBetterKertajati = (id) =>
  ConnectionInstance.get("api/payments/betterkertajatifestival/monitoring");
export const getCountFisipNightRun = (id) =>
  ConnectionInstance.get("api/payments/fspnr/monitoring");
